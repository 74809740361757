import { OnboardingStatusEnum, RoleEnum } from '@sr-sdks/api-sdk-axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userService } from '../reducers/permissions/userSlice';
import { useTypedSelector } from '../stateStore';

interface WorkspaceRedirectProps {
  children: React.ReactNode;
}

export const WorkspaceRedirect: React.FC<WorkspaceRedirectProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const userMe = useTypedSelector(userService.selectors.userMe);

  useEffect(() => {
    if (userMe) {
      for (const role of userMe.roles) {
        if (
          role.role.key === RoleEnum.WorkspaceOwner &&
          role.onboardingStatus !== OnboardingStatusEnum.Completed
        ) {
          navigate(`/onboarding/${role.workspaceId}/setup-workspace`);
          break;
        }
      }
    }
  }, [userMe, navigate]);

  return <>{children}</>;
};
